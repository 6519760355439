.about_wrapper {
    background-color: $dark-blue;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5vh 10vw;
}

.staff_image {
    display: none;
}

.staff_title {
    color: #F6F6F6;
    font-size: 1rem;
    text-transform: uppercase;
}

.staff_text {
    color: #F6F6F6;
    text-align: center;
}

.staff_info {
    padding-top: 5vh;
}

//desktop
@media (min-width: 768px) {
    .about_wrapper {
        height: auto;
        padding: 15vh 10vw 15vh 10vw;
    }

    .about_staff {
        display: flex;
        flex-direction: column;
        padding: 5vh 0;
        justify-items: flex-start;
    }
    
    .staff_section {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 5vh 0;
        width: 80vw;
    }

    .staff_image {
        display: flex;
        width: 15vw;
        height: 15vw;
        object-fit: cover;
        border-radius: 20px;
    }

    .staff_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 2vw;
    }

    .staff_title {
        color: #F6F6F6;
        font-size: 1.25rem;
        text-transform: uppercase;
    }

    .staff_text {
        width: 60vw;
        color: #F6F6F6;
        text-align: start;
    }
}