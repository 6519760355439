.header_wrapper{
    width: 90vw;
    height: 8vh;
    background-color: $off-white;
    display: flex;
    justify-content: space-between;
    padding: 0 5vw;
    align-items: center;
    border-bottom: 2px solid $dark-blue;
}

.header-logo{
    height: 5vh;
}

.header-nav_wrapper{
    display: flex;
    flex-direction: row;
    width: 30vw;
    justify-content: space-evenly;
    align-items: center;
}

.header-nav-link{
    text-decoration: none;
    color: $dark-blue;
    font-weight: 500;
    font-size: $subtitle-desktop;
}

.header-nav-link:hover{
    border-bottom: 1px solid $dark-blue;
}

@media (max-width: 1200px) {
    .header-nav_wrapper{
        width: 50vw;
    }
}