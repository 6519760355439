//settings
$off-white: #F6F6F6;
$light-blue: #83C8BB;
$dark-blue: #00303C;

$title-desktop: 2rem;
$subtitle-desktop: 1.5;
$small-title-desktop: 1.2rem;
$text-desktop: 1.2rem;

$title-mobile: 1.225rem;
$subtitle-mobile: 1.15;
$small-title-mobile: 1rem;
$text-mobile: 1rem;



//imports
@import './components/landing/landing.scss';
@import './components/layout/mobileMenu/mobileMenu.scss';
@import './components//layout/header/header.scss';
@import './components/about/about.scss';
@import './components/information/information.scss';
@import './components/contact/contact.scss';
@import './components/gdpr/gdpr.scss';

.sticky {
  position: sticky;
  top: 0;
}

h1 {
  margin: 0;
  color: $off-white;
  padding-bottom: 3vh;
  font-weight: 700;
  font-size: $title-mobile;
  text-align: center;
}

h2 {
  margin: 0;
  color: $dark-blue;
  padding-bottom: 2vh;
  font-weight: 700;
  font-size: $title-mobile;
}

h3 {
  margin: 0;
  color: $dark-blue;
  padding: 4vh 0 1vh 0;
  font-weight: 600;
  font-size: $subtitle-mobile;
}

h4 {
  margin: 0;
  color: $dark-blue;
  padding: 2vh 0 1vh 0;
  font-weight: 500;
  font-size: $small-title-mobile;
}

p {
  width: 80vw;
  font-size: $text-mobile;
  font-weight: 400;
  padding-bottom: 1.5vh;
  margin: 0;
}

ul {
  margin: 0;
}

li {
  padding: 0.5vh;
}

.center {
  color: $off-white;
  text-align: center;
  padding: 2vh 0;
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media (min-width: 768px) {
  h1 {
    margin: 0;
    padding-bottom: 3vh;
    font-size: $title-desktop;
  }

  h2 {
    padding-bottom: 2vh;
    font-size: $title-desktop;
  }
  
  h3 {
    padding: 4vh 0 0 0;
    font-size: $subtitle-desktop;
  }
  
  h4 {
    padding: 2vh 0 0 0;
    font-size: $small-title-desktop;
  }
  
  p {
    font-size: $text-desktop;
    padding-bottom: 1.5vh;
  }
}