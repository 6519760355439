.mobile-menu_wrapper {
    width: 10vw;
    height: 10vh;
    position: fixed;
    right:0;
    top:0;
    overflow: hidden;
}

.menu-open{
    width: 100vw;
    height: 100vh;
}

.open-menu-icon, .close-menu-icon {
    position: absolute;
    right: 0;
    top: 0;
    margin: 4vw;
    width: 5vw;
    z-index: 3;
}

.mobile-menu-list_wrapper {
    width: 100vw;
    height: 100vh;
    background-color: $dark-blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.mobile-menu-logo{
    width: 30vw;
    padding-bottom: 5vh;
}

.mobile-menu-list{
    display: flex;
    flex-direction: column;
    height: 20vh;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}

.mobile-menu-option{
    color: $off-white;
    text-decoration: none;
    padding: 2.5vh;
    font-size: $subtitle-mobile;
}