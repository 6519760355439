.contact_wrapper{
    display: flex;
    flex-direction: column;
    background-color: $dark-blue;
    padding: 7.5vh 10vw 5vh 10vw;
}

.location-link_wrapper, .contact-form-send{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-blue;
    width: 80vw;
    height: 6vh;
    align-self: center;
    color: $dark-blue;
    font-weight: 500;
    text-decoration: none;
    margin: 1vh 0;
    border-radius: 2px;
}

.contact-info_wrapper{
    color: $off-white;
    text-align: center;
    padding: 2vh 0;
}

.contact-info{
    display: flex;
    flex-direction: row;
    height: 7.5vh;
    align-items: center;
    padding-left: 5vw;
    width: 75vw;
}

.contact-info-img{
    width: 6vw;
    padding-right: 5vw;
}

.contact-info-text{
    display: flex;
    justify-self: flex-start;
}

.contact-form_wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 2vh 0;
}

.contact-form_wrapper label{
    color: $off-white;
}

.contact-form_wrapper input, .contact-form_wrapper textarea{
    width: 95%;
    padding: 1vh 2vw;
    border: none;
    border-radius: 2px;
    margin: 0.5vh 0 1vh 0;
    font-size: $text-mobile;
    color: $dark-blue;
    background: $off-white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.contact-form_wrapper input:focus, .contact-form_wrapper textarea:focus {
    outline: none;
}


.contact-form_wrapper textarea {
    resize: none;
    height: 15vh;
}

.contact-form-send {
    margin-top: 2vh;
}



@media (min-width: 768px) {

    .contact_wrapper{
        padding: 15vh 10vw 15vh 10vw;
    }

    .map-embed{
        width: 80vw;
        height: 50vh;
        border: none;
        padding: 2vh 0;
        align-self: center;
    }

    .contact-info_wrapper{
        display: flex;
        justify-content: space-around;
        padding-top: 5vh;
    }

    .contact-info{
        height: 7.5vh;
        align-items: center;
        justify-content: center;
        padding-left: 3vw;
        width: 100vw;
    }

    .contact-info-img{
        width: 2.5vw;
        padding-right: 1vw;
    }

    .contact-form_wrapper{
        align-self: center;
        width: 40vw;
        padding-top: 5vh;
    }

    .contact-form_wrapper input, .contact-form_wrapper textarea{
        padding: 1vh 1vw;
    }

    .contact-form_wrapper textarea {
        height: 10vh;
    }

    .contact-form-send {
        width: 40vw;
    }
}