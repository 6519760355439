.landing_wrapper {
    width: 100vw;
    height: 100vh;
    background-color: $off-white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.landing-logo {
    width: 50vw;
}


//desktop
@media (min-width: 768px) {
    .landing_wrapper{
        height: 92vh;
    }

    .landing-logo {
        width: 20vw;
    }
}