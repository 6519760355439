.gdpr_wrapper {
    background-color: $off-white;
    padding: 10vh 10vw 5vh 10vw;
}

.data-collection-list, .data-parties-list {
    padding-bottom: 1.5vh;
}

@media (min-width: 768px) {
    .gdpr_wrapper {
        padding: 15vh 10vw 10vh 10vw;
    }
}